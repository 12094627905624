<template>
  <div class="row">
    <div class="col-md-12 ml-3">
      <div class="clearfix mb-1">
        <div class="float-left">
          <button type="button" class="btn btn-primary" @click="openModal" v-if="description && !eventCenter.id">
            <i class="fal fa-edit"></i>&nbsp;&nbsp;&nbsp;{{ description }}
          </button>
          <button type="button" class="btn btn-light" @click="openModal" v-else-if="!eventCenter.id">
            <i class="fal fa-bars"></i>&nbsp;&nbsp;&nbsp;Local do evento
          </button>
          <button type="button" class="btn btn-primary" :disabled="disabled" @click="openModal" v-else>
            <i class="fal fa-edit"></i>&nbsp;&nbsp;&nbsp;{{ eventCenter.name }}
          </button>
        </div>
      </div>
      <b-form-invalid-feedback id="input-eventCenter-feedback" :style="setDisplay">
        Selecione o local do evento <i class="fal fa-info-circle"></i>
      </b-form-invalid-feedback>
    </div>

    <ModalEventCenter v-on:emmitEventCenter="getEvent" />

  </div>
</template>

<script>
import ModalEventCenter from "@/components/eventCenter/ModalEventCenter";
export default {
  name: "ButtonEventCenter",
  props: ['error', 'description', 'disabled'],
  components: {ModalEventCenter},
  computed: {
    setDisplay() {
      return this.error ? 'display: block' : 'display: none'
    }
  },
  data: () => ({
    eventCenter: {
      id: null,
      name: null
    }
  }),
  methods: {
    openModal() {
      this.$bvModal.show('modal-event-center')
    },
    getEvent(value) {
      this.eventCenter.id   = value.id
      this.eventCenter.name = value.descricao
      this.$emit('emmitEventCenter', value)
    }
  }
}
</script>

<style scoped>

</style>