import { render, staticRenderFns } from "./CardEventCenter.vue?vue&type=template&id=2e82f512&scoped=true&"
import script from "./CardEventCenter.vue?vue&type=script&lang=js&"
export * from "./CardEventCenter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e82f512",
  null
  
)

export default component.exports