<template>
  <div class="clearfix">
    <div class="titulo-pagina float-left">
      <h5><i class="fal fa-chevron-right"></i> {{ title }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondaryMenu",
  props: ['title']
}
</script>

<style scoped>

</style>