<template>
  <div>
    <b-modal ref="modal-event-center" hide-footer id="modal-event-center" title="Centro de Eventos">
      <div class="d-block text-center">

        <b-col md="12">
          <b-input-group>
            <b-input class="form-control" placeholder="Buscar..." v-model="search" @keyup="setSearch"></b-input>
            <b-input-group-append><b-button variant="outline-secondary"><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="12 mt-3" v-if="centerEvents.length">
          <CardEventCenter v-for="item in centerEvents" :key="item.id" :item=item v-on:setEventCenter="getItem" />
        </b-col>

        <b-col md="12 mt-3" v-else>
          <b-skeleton animation="wave" height="100px" width="100%"></b-skeleton>
          <b-skeleton animation="wave" height="100px" width="100%"></b-skeleton>
          <b-skeleton animation="wave" height="100px" width="100%"></b-skeleton>
        </b-col>

      </div>
    </b-modal>
  </div>

</template>

<script>
import CardEventCenter from "@/components/eventCenter/CardEventCenter";
import {mapActions} from 'vuex'

export default {
  name: "ModalEventCenter",
  created() {
    this.get()
  },
  data: () => ({
    centerEvents: [],
    items: [],
    search: ''
  }),
  methods: {
    ...mapActions('centerEvents', ['getAll']),

    async get() {
      this.centerEvents = await this.getAll()
      this.items        = this.centerEvents
    },

    getItem(object) {
      this.$emit('emmitEventCenter', object)
      this.$bvModal.hide('modal-event-center')
    },

    setSearch() {
      if (this.search !== '') {
        const object = [];
        for (let w = 0; w < this.items.length; w++) {
          const current = this.items[w];
          if (current.descricao.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
            object.push(current)
          }
        }
        this.centerEvents = object
      } else {
        this.centerEvents = this.items
      }
    }
  },
  components: {CardEventCenter}
}
</script>

<style scoped>

</style>