<template>
  <div class="mt-2">
    <div class="outer-div">

      <div v-bind:style="`background-image: url(${photo})`" class="card cardEvento-centro-evento cursor-pointer" @click="setItem(item)">
        <div class="card-body">
          <div class="cardEvento-centro-evento-nome">
            <label>{{ item.descricao }}</label>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "CardEventCenter",
  props: ['item'],
  computed: {
    photo() {
      return 'https://sistemas.usb.org.br' + this.item.foto
    }
  },
  methods: {
    setItem(object) {
      this.$emit('setEventCenter', object)
    }
  }
}
</script>

<style scoped>

</style>