/**
 * API
 *
 * https://sweetalert2.github.io/
 */


export default function setNotification(el, type, message) {

    if (type === 'error') {
        showError(el, message)
    }

    if (type === 'success') {
        showSuccess(el, message)
    }
}


function showError(el, message) {

    el.$swal({
        toast: true,
        position: 'bottom-end',
        timerProgressBar: false,
        icon: 'error',
        title: ' ',
        text: message,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'ok',
        allowEscapeKey: true,
        cancelButtonColor: '#dd6b55'
    })
}


function showSuccess(el, message) {

    el.$swal({
        toast: true,
        position: 'top-end',
        timer: 5000,
        timerProgressBar: true,
        icon: 'success',
        title: message,
        showConfirmButton: false
    })
}
